@use '../variables.scss' as *;

$left-right-content-padding: 16px;
$borderColor: var(--neutralLight);
// A11y fix: See the comment in the optionsListBody#scroll-padding-top class for more information.
// Important note, this needs to be a fixed value because we need to keep the height of the sticky header in-sync with
// the value of optionsListBody#scroll-padding-top. If we don't, there's a chance we can have the a11y bug again
// because the sticky header can overlap focusable elements.
$headSegmentHeight: 40px;

.optionsListBody {
    flex: 0 1 auto;
    min-height: 0;
    padding: 0 $left-right-content-padding 8px $left-right-content-padding;

    overflow: auto;
    // A11y fix: this is needed because without it the sticky header can overlap focusable elements
    // as a keyboard user navigates through the page with 200% zoom level
    // https://cerovac.com/a11y/2023/07/wcag-2-2-brings-more-bad-news-for-sticky-elements-and-more-good-news-for-users/#:~:text=Using%20CSS%20scroll%2Dpadding%20(opens%20in%20new%20window)%2C%20which%20can%20prevent%20overlapping%20other%20content.%20Scroll%2Dpadding%20has%20quite%20good%20support%20in%20browsers%20(opens%20in%20new%20window)%2C%20by%20the%20way%2C%20unless%20you%20need%20support%20for%20Internet%20Explorer
    scroll-padding-top: $headSegmentHeight;

    display: grid;
    // grid-auto-flow: column;
    grid-template-columns: minmax(0, 1fr);
    // Important that we use gap here, so individual elements can apply there
    // own margins to adjust spacing optionally.
    gap: $space-between-options;

    // Variables for visual options
    --border-color: var(--neutralLight);
    --left-right-padding: #{$left-right-content-padding};
    --option-spacing: #{$space-between-options};
}

.sectionDivider {
    // At 150% monitor scaling (1 browser pixels = 1.5 actual pixels) & and
    // a 1px height, Chrome sometimes makes this 2px and sometimes makes this 1px
    // https://stackoverflow.com/questions/42710882/css-border-1px-appear-as-0-667px-or-1px-depending-on-the-computer-display-res
    height: 1px;
    background-color: var(--neutralTertiary);

    // Remove <hr /> border and margin styles
    border: none;
    margin: 0;
}

.collapseButton {
    padding: 0 $left-right-content-padding;
    margin-bottom: 12px !important;
    flex: 0 0 auto;
    font-weight: 400 !important;
    width: max-content;
    justify-content: flex-start !important;
}
