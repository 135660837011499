@use '../variables.scss' as visualOptions;
@use './Container.module.scss' as container;

$transition-time: 0.2s;

// Size of each section title should be based on this to ensure they are the
// same size.
.section-title {
    $font-size: 16px;
    $line-height: 22px;

    font-size: $font-size;
    line-height: $line-height;
    font-weight: 600;
    $vertical-padding: 12px - calc(($line-height - $font-size) / 2);
    padding: $vertical-padding 0;
    color: var(--neutralPrimary);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.head {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: sticky;
    height: container.$headSegmentHeight;
    top: 0;
    z-index: 1;
    background-color: var(--white);
    margin: 0px -1 * container.$left-right-content-padding;
    > * {
        margin: 0;
        &:not(:last-child) {
            margin-right: 8px;
        }
    }

    &.hasToggle {
        padding-right: container.$left-right-content-padding;
        .commandBarButton {
            padding-left: container.$left-right-content-padding;
            margin-right: 8px;
        }
    }

    &:not(.hasToggle) {
        .commandBarButton {
            padding: 0 container.$left-right-content-padding;
        }
    }
}

.commandBarButton {
    $iconWidth: 16px;
    $iconMarginRight: visualOptions.$section-left-padding - $iconWidth;

    flex: 1 1 auto;
    text-align: left;
    i {
        transition: transform $transition-time;
        transform: rotate(-0.25turn);
        margin: 2px $iconMarginRight 0 0;
        color: var(--black);
    }

    // Ensure overflowing text is truncated
    :global(.ms-Button-textContainer) {
        flex: 1 1;
        width: 0;
        height: unset;
    }
    :global(.ms-Button-label) {
        @extend .section-title;
        margin: 0;
    }
}

.open .commandBarButton i {
    transform: rotate(0);
}

.slideoutOuter {
    display: flex;
    flex-direction: column;
    transition: height $transition-time ease-out;
}

.slideoutInner {
    padding: 4px 0 16px visualOptions.$section-left-padding;

    display: grid;
    // grid-auto-flow: column;
    grid-template-columns: minmax(0, 1fr);
    // Important that we use gap here, so individual elements can apply there
    // own margins to adjust spacing optionally.
    gap: visualOptions.$space-between-options;
}

.emptySection {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 0 visualOptions.$section-left-padding;
}

.emptySectionTitle {
    margin-right: auto;
    @extend .section-title;
}
