$ag-icons-path: '../connectionExplorer/balham-icons/light/';

@media screen and (-ms-high-contrast: active) {
    .connection-pane.ag-theme-balham .ag-row.ag-row-selected {
        background-color: highlight !important;
        border: 1px solid highlight !important;
    }
}

.ag-theme-balham-dark .datatype {
    text-align: right;
    color: #666666;
    font-size: 12px;
}
.ag-theme-balham .ag-tool-panel .ag-side-buttons button:focus {
    outline: #fff solid 1px;
}

.connection-pane.ag-theme-balham .ag-root-wrapper {
    border-width: 1px 0 0 0;
}

.connection-pane.ag-theme-balham .ag-row.ag-row-selected {
    background-color: #b7e4ff;
}

.ag-theme-balham .ag-cell-search-hit {
    background-color: rgba(255, 255, 0, 0.2);
    color: #000;
}

.ag-theme-balham .ag-cell-search-hit-current {
    background-color: rgba(236, 93, 0, 0.3);
    color: #000;
}

.ag-theme-balham .ag-cell-search-hit-focus {
    border: 1px solid #0091ea !important;
}

.ag-theme-balham .ag-icon-tree-closed {
    color: #323130;
}

.ag-theme-balham .ag-icon-tree-open {
    color: #323130;
}

.ag-theme-balham .ag-toggle-button-input-wrapper:focus-within {
    box-shadow: 0 0 2px 1px #3e6d9e;
}

.ag-theme-balham .ag-column-select .ag-checkbox-input-wrapper:focus-within {
    box-shadow: 0 0 2px 1px #3e6d9e;
}

.ag-theme-balham .ag-column-select .ag-input-field-input.ag-text-field-input:focus {
    box-shadow: 0 0 2px 1px #3e6d9e;
}
