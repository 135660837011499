@use '../../VisualModel/variables.scss' as visualOptions;
@use '../configurationList/styles.module.scss' as configList;

.mainInputsContainer {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: inherit;
    max-height: 500px;
    padding-right: visualOptions.$space-between-options;
}

.pairsContainer {
    display: flex;
    flex-direction: column;
    gap: inherit;

    & > :not(:first-child) {
        border-top: 1px solid var(--neutralLight);
        padding-top: visualOptions.$space-between-options;
    }
}

.pair {
    display: inherit;
    flex-direction: inherit;
    gap: inherit;
}

.rootInteractions {
    // Only trim if there are no cross filter rows
    display: grid;
    grid-template-columns: auto auto;
    margin-top: configList.$marginAdjustment;
    // No items style
    &:first-child {
        margin-top: calc(configList.$marginAdjustment + 8px);
    }
    margin-bottom: calc(configList.$marginAdjustment + 8px);
}

.resetButton {
    grid-area: extra-content;
}

.formAddDrillthrough {
    margin-top: configList.$marginAdjustment;
    margin-bottom: configList.$marginAdjustment;
}
