@use '@kusto/style/tokens';

$horizontal-padding: 12px;
$vertical-padding: 8px;

.errorMessage {
    border-radius: tokens.$borderRadiusXLarge;
    height: 100%;
    display: flex;

    :global(.ms-MessageBar-content) {
        min-height: 0;
        padding: $vertical-padding $horizontal-padding;
        box-sizing: border-box;
        // First child is icon container, but isn't style-able
        > div:first-child {
            margin: 0;
        }
    }
    :global(.ms-MessageBar-text) {
        overflow-y: auto;
        margin: 0;
        // Space between icon and text
        padding-left: 8px;
        box-sizing: border-box;
    }
    :global(.ms-MessageBar-innerText) {
        > span > *:first-child {
            margin-top: 0;
        }

        white-space: pre-line;
    }
    // Default message bar actions uses `flex-direction: row-reverse` to left
    // align actions, but, I don't think they tested it because the spacing and
    // order of buttons are messed up
    :global(.ms-MessageBar-actions) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: right;
        // Remove margins added by fluent ui because they don't work with wrap enabled, and add a gap
        gap: 4px 8px;
        button {
            margin: 0;
        }
    }
}

.infoMessage {
    container-name: infoLayout;
    container-type: size;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: tokens.$spacingVerticalS;
    justify-content: safe center;
    overflow-y: auto;
    width: 100%;
    button {
        min-height: 32px;
    }
}

@container infoLayout (height < 200px) {
    .icon {
        display: none;
    }
}

.icon {
    color: tokens.$colorNeutralStroke1;
    font-size: 50px;
}

.title {
    font-size: 18px;
    font-weight: tokens.$fontWeightSemibold;
}

.body {
    color: tokens.$colorNeutralForeground4;
    text-align: center;
}
