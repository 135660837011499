.minimizeButton {
    position: absolute;
    top: 4px;
    right: 4px;
}

.verticalText {
    position: relative;
    width: 100%;
    top: 40px;
    writing-mode: vertical-rl;
    line-height: 40px;
}
