.maximized {
    background: var(--colorNeutralBackground1);
    // position - set with inline style in component
    inset: 0;
    z-index: 1000;
}

.minimized {
    position: relative;
    height: 100%;
}
