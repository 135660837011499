.container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.footer {
    display: flex;
    gap: 8px;
}
