.searchPlaceholder {
    display: inline-flex;
    height: 100%;
    width: 100%;
}

.searchContainerHidden {
    display: none;
}

.searchContainerFadeOut {
    animation: fade-out 500ms;
    animation-fill-mode: forwards;
}

.searchContainerVisible {
    animation: fade-in 500ms;
    animation-fill-mode: forwards;
}

.searchBar {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;

    > button {
        height: 100%;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.ag-theme-balham .highlight-by-icon {
    fill: black;
}

.ag-theme-balham-dark .highlight-by-icon {
    fill: #f5f5f5;
}
