@use '@kusto/style/tokens';

$outline: #666 solid 1px;
$kusto-inverted-text-color: #f3f2f1;
$color-errorBackgroundLight: #f8696b;
$color-errorForegroundLight: white;
$color-verboseForegroundLight: mediumblue;
$color-warningBackgroundLight: #ffeb84;
$color-criticalForegroundLight: white;
$color-criticalBackgroundLight: black;

.ag-theme-balham .ag-column-panel .ag-column-drop .ag-column-drop-empty-message {
    color: rgba(0, 0, 0, 0.6);
}

.ag-theme-balham .ag-root .ag-header {
    background-color: #f5f5f5;
}

.ag-theme-balham .ag-root .ag-header-icon {
    color: #333333;
}

.ag-theme-balham .ag-root .ag-header-row {
    color: #333333;
}

// Fixes AG-grids bug with edge v102 - horizontal scrollbar not clickable.
// https://github.com/ag-grid/ag-grid/issues/5279
.ag-body-horizontal-scroll.ag-scrollbar-invisible {
    pointer-events: all !important;
}

.ag-column-panel {
    overflow-y: auto !important;
}

.ag-theme-balham .ag-root {
    border-color: #8a8886 !important;
}

.ag-theme-balham .ag-header {
    border-bottom-color: #8a8886 !important;
}

.ag-header-cell .ag-cell-label-container {
    justify-content: start;
}

.ag-theme-balham .ag-keyboard-focus button.ag-side-button-button:focus:after {
    border-color: #6794c4;
}

#table-result-container {
    .ag-root {
        border-left-width: 0px;
        border-bottom-width: 0px;
    }
    .ag-side-buttons {
        border-bottom-width: 0px;
    }
    .ag-side-bar {
        border-right-width: 0px;
    }
    .ag-status-bar {
        border-width: 1px 0px 0px 0px;
    }
    .ag-header-cell {
        overflow: hidden;
    }
    // Add outline to header - accessibility fix
    .ag-header-cell.kusto-query-header .ag-cell-label-container:focus {
        outline: auto;
        padding: 5px;
        margin: -5px;
        box-sizing: content-box;
    }
    .ag-popup .ag-menu *:focus {
        outline: auto;
    }

    .ag-toggle-button-input-wrapper:focus-within {
        @media (-ms-high-contrast: active) {
            outline: 1px solid highlight;
        }
    }

    &.ag-theme-balham .ag-row:not(.ag-row-hover) {
        &.error-color {
            background: $color-errorBackgroundLight;
            color: $color-errorForegroundLight;
        }
        &.warning-color {
            background: $color-warningBackgroundLight;
        }
        &.verbose-color {
            color: $color-verboseForegroundLight;
        }
        &.critical-color {
            background: $color-criticalBackgroundLight;
            color: $color-criticalForegroundLight;
        }
    }

    .ag-status-bar {
        border-top: 1px solid rgba(128, 128, 128, 0.24);
        margin-bottom: -1px;
    }
    .ag-header-cell-menu-button {
        opacity: 1 !important; //always show menu button, default behavior is only on hover
    }
}

.ag-cell {
    .cf-wrapper {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .cf-icon {
        margin-right: 4;
        vertical-align: bottom;
        svg {
            height: 26;
            width: 12;
            vertical-align: bottom;
        }
    }
    .cf-content {
        flex-grow: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .cf-sub-label {
        margin-left: 8;
        line-height: 16px;
        display: inline-block;
        border-style: solid;
        border-width: 1;
        padding: 0 4px;
        border-radius: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.ag-theme-balham .ag-column-select-panel .ag-column-tool-panel-column .ag-column-select-checkbox[tabindex]:focus {
    outline: $outline;
}
.ag-theme-balham .ag-pivot-mode-select {
    margin: 1px 4px 0px 1px;
}
.ag-theme-balham .ag-pivot-mode-select:focus {
    outline: none;
}
.ag-theme-balham .ag-tool-panel-wrapper .ag-pivot-mode-panel span span {
    height: 20px;
}
.ag-theme-balham .ag-pivot-mode-select > span {
    margin-top: 5px;
}
.ag-theme-balham .ag-pivot-mode-select .ag-checkbox-label {
    margin-top: 0;
}
.ag-theme-balham .ag-pivot-mode-select:focus > span:not(.ag-hidden):not(.ag-checkbox-label) {
    outline: $outline;
}
.ag-side-bar .ag-side-buttons div button {
    cursor: pointer;
}

.ag-theme-balham .ag-cell-range-selected {
    border-width: 2px !important;
}

.ag-side-bar .ag-side-buttons div button:focus {
    outline: $outline;
}
.ag-column-select-panel .ag-primary-cols-header-panel a {
    line-height: 30px;
    padding: 0;
}

.ag-column-select {
    min-height: 70px;
}

.ag-column-select-panel .ag-primary-cols-filter {
    border: #a6a6a6 solid 1px;
    padding-left: 2px;
}
.ag-column-select-panel .ag-primary-cols-filter:hover {
    border: #333 solid 1px;
}
.ag-column-select-panel .ag-primary-cols-filter:focus {
    border: #0078d4 solid 1px;
    outline: none;
}
.ag-column-select-panel .ag-primary-cols-header-panel a:focus {
    outline: none;
}
.ag-column-select-panel .ag-primary-cols-header-panel a:focus .ag-icon {
    outline: $outline;
}
.ag-column-select-panel .ag-primary-cols-header-panel .ag-icon {
    padding: 1px;
}
.highlighted-url {
    color: tokens.$colorBrandForegroundLink;
    border: none;
    outline: none;
    width: 100%;
    &:active {
        color: tokens.$colorBrandForegroundLinkPressed;
    }
    &:hover {
        color: tokens.$colorBrandForegroundLinkHover;
    }
}

// Adding !important to the background color, to avoid ag-grid overriding it with specific rules, like range selection, etc..
.ag-theme-balham .ag-row,
.ag-theme-balham .ag-row .ag-cell {
    &.highlight-color-0 {
        background-color: #c7e0f4 !important;
        color: #000;
    }
    &.highlight-color-1 {
        background-color: #55b17e !important;
        color: #000;
    }
    &.highlight-color-2 {
        background-color: #a864cd !important;
        color: #000;
    }
    &.highlight-color-3 {
        background-color: #dc816a !important;
        color: #000;
    }
    &.highlight-color-4 {
        background-color: #d76787 !important;
        color: #000;
    }
    &.highlight-color-5 {
        background-color: #bdbde6 !important;
        color: #000;
    }
    &.highlight-color-6 {
        background-color: #6794d7 !important;
        color: #000;
    }
    &.highlight-color-7 {
        background-color: #98d6d8 !important;
        color: #000;
    }
    &.highlight-color-8 {
        background-color: #e8825d !important;
        color: #000;
    }
    &.highlight-color-9 {
        background-color: #55b17e !important;
        color: #000;
    }
    &.highlight-color-10 {
        background-color: #c087b8 !important;
        color: #000;
    }
    &.highlight-color-11 {
        background-color: #9cbeff !important;
        color: #000;
    }
    &.highlight-color-12 {
        background-color: #f7de6d !important;
        color: #000;
    }
    &.highlight-color-13 {
        background-color: #d98fd9 !important;
        color: #000;
    }
    &.highlight-color-14 {
        background-color: #94bfff !important;
        color: #000;
    }
    &.highlight-color-15 {
        background-color: #14848f !important;
        color: #000;
    }
    &.highlight-color-16 {
        background-color: #eff6fc !important;
        color: #000;
    }
    &.highlight-color-17 {
        background-color: #caead8 !important;
        color: #000;
    }
    &.highlight-color-18 {
        background-color: #e6d1f2 !important;
        color: #000;
    }
    &.highlight-color-19 {
        background-color: #f6dbd4 !important;
        color: #000;
    }
    &.highlight-color-20 {
        background-color: #f4d2dc !important;
        color: #000;
    }
    &.highlight-color-21 {
        background-color: #f4f4fc !important;
        color: #000;
    }
    &.highlight-color-22 {
        background-color: #d2e0f4 !important;
        color: #000;
    }
    &.highlight-color-23 {
        background-color: #f0f9fa !important;
        color: #000;
    }
    &.highlight-color-24 {
        background-color: #f9dcd1 !important;
        color: #000;
    }
    &.highlight-color-25 {
        background-color: #caead8 !important;
        color: #000;
    }
    &.highlight-color-26 {
        background-color: #eedbeb !important;
        color: #000;
    }
    &.highlight-color-27 {
        background-color: #e7efff !important;
        color: #000;
    }
    &.highlight-color-28 {
        background-color: #fdf6d7 !important;
        color: #000;
    }
    &.highlight-color-29 {
        background-color: #f6e4f6 !important;
        color: #000;
    }
    &.highlight-color-30 {
        background-color: #e6f0ff !important;
        color: #000;
    }
    &.highlight-color-31 {
        background-color: #cff6f9 !important;
        color: #000;
    }
}

.ag-theme-balham .ag-row,
.ag-theme-balham .ag-row .ag-cell {
    // blue theme
    &.blue-theme-0 {
        background-color: #1b61a5 !important;
        color: $kusto-inverted-text-color;
    }
    &.blue-theme-1 {
        background-color: #1467b1 !important;
        color: $kusto-inverted-text-color;
    }
    &.blue-theme-2 {
        background-color: #0e6cbc !important;
        color: $kusto-inverted-text-color;
    }
    &.blue-theme-3 {
        background-color: #0772c8 !important;
        color: $kusto-inverted-text-color;
    }
    &.blue-theme-4 {
        background-color: #0078d4 !important;
    }
    &.blue-theme-5 {
        background-color: #0d7ddd !important;
    }
    &.blue-theme-6 {
        background-color: #1a82e5 !important;
    }
    &.blue-theme-7 {
        background-color: #2688ee !important;
    }
    &.blue-theme-8 {
        background-color: #338df6 !important;
    }
    &.blue-theme-9 {
        background-color: #4092ff !important;
    }

    // cold theme
    &.cold-theme-0 {
        background-color: #216895 !important;
        color: $kusto-inverted-text-color;
    }
    &.cold-theme-1 {
        background-color: #217491 !important;
        color: $kusto-inverted-text-color;
    }
    &.cold-theme-2 {
        background-color: #20818d !important;
    }
    &.cold-theme-3 {
        background-color: #208d89 !important;
    }
    &.cold-theme-4 {
        background-color: #1f9a85 !important;
    }
    &.cold-theme-5 {
        background-color: #19a790 !important;
    }
    &.cold-theme-6 {
        background-color: #13b49b !important;
    }
    &.cold-theme-7 {
        background-color: #0cc1a6 !important;
    }
    &.cold-theme-8 {
        background-color: #06ceb1 !important;
    }
    &.cold-theme-9 {
        background-color: #00dbbc !important;
    }

    // red theme
    &.red-theme-0 {
        background-color: #a31b15 !important;
        color: $kusto-inverted-text-color;
    }
    &.red-theme-1 {
        background-color: #ab1818 !important;
        color: $kusto-inverted-text-color;
    }
    &.red-theme-2 {
        background-color: #b4151a !important;
        color: $kusto-inverted-text-color;
    }
    &.red-theme-3 {
        background-color: #bc121d !important;
        color: $kusto-inverted-text-color;
    }
    &.red-theme-4 {
        background-color: #c50f1f !important;
        color: $kusto-inverted-text-color;
    }
    &.red-theme-5 {
        background-color: #cc2431 !important;
        color: $kusto-inverted-text-color;
    }
    &.red-theme-6 {
        background-color: #d33943 !important;
        color: $kusto-inverted-text-color;
    }
    &.red-theme-7 {
        background-color: #da4f55 !important;
    }
    &.red-theme-8 {
        background-color: #e16467 !important;
    }
    &.red-theme-9 {
        background-color: #e87979 !important;
    }

    // traffic-lights theme
    &.traffic-lights-theme-0 {
        background-color: #a8361d !important;
        color: $kusto-inverted-text-color;
    }
    &.traffic-lights-theme-1 {
        background-color: #b64f27 !important;
        color: $kusto-inverted-text-color;
    }
    &.traffic-lights-theme-2 {
        background-color: #c46732 !important;
    }
    &.traffic-lights-theme-3 {
        background-color: #d2803c !important;
    }
    &.traffic-lights-theme-4 {
        background-color: #e09846 !important;
    }
    &.traffic-lights-theme-5 {
        background-color: #be9240 !important;
    }
    &.traffic-lights-theme-6 {
        background-color: #9d8c39 !important;
    }
    &.traffic-lights-theme-7 {
        background-color: #7b8533 !important;
    }
    &.traffic-lights-theme-8 {
        background-color: #5a7f2c !important;
    }
    &.traffic-lights-theme-9 {
        background-color: #387926 !important;
        color: $kusto-inverted-text-color;
    }

    // warm theme
    &.warm-theme-0 {
        background-color: #a82e0f !important;
        color: $kusto-inverted-text-color;
    }
    &.warm-theme-1 {
        background-color: #b53d0b !important;
        color: $kusto-inverted-text-color;
    }
    &.warm-theme-2 {
        background-color: #c34d08 !important;
        color: $kusto-inverted-text-color;
    }
    &.warm-theme-3 {
        background-color: #d05c04 !important;
    }
    &.warm-theme-4 {
        background-color: #de6c00 !important;
    }
    &.warm-theme-5 {
        background-color: #de750e !important;
    }
    &.warm-theme-6 {
        background-color: #df7e1c !important;
    }
    &.warm-theme-7 {
        background-color: #df862a !important;
    }
    &.warm-theme-8 {
        background-color: #e08f38 !important;
    }
    &.warm-theme-9 {
        background-color: #e09846 !important;
    }

    // yellow theme
    &.yellow-theme-0 {
        background-color: #de750e !important;
    }
    &.yellow-theme-1 {
        background-color: #df7e1c !important;
    }
    &.yellow-theme-2 {
        background-color: #df862a !important;
    }
    &.yellow-theme-3 {
        background-color: #e08f38 !important;
    }
    &.yellow-theme-4 {
        background-color: #e09846 !important;
    }
    &.yellow-theme-5 {
        background-color: #e6a738 !important;
    }
    &.yellow-theme-6 {
        background-color: #ecb62a !important;
    }
    &.yellow-theme-7 {
        background-color: #f1c51c !important;
    }
    &.yellow-theme-8 {
        background-color: #f7d40e !important;
    }
    &.yellow-theme-9 {
        background-color: #fde300 !important;
    }
}
