.containerStyle {
    display: flex;
    align-items: center;
    gap: var(--spacingHorizontalXS);

    .iconStyle {
        min-width: 24px;
        min-height: 24px;
        color: var(--colorNeutralForeground2);
    }

    .navItem {
        display: flex;
        align-items: center;

        .buttonStyle {
            color: var(--colorNeutralForeground2);
            white-space: nowrap;
            overflow: hidden;
            display: grid;
            text-overflow: ellipsis;
        }

        .nonClickable {
            cursor: default;
        }
    }
}
