$color-themeSecondary: #2b88d8;
$color-neutralSecondary: #666666;

.searchbox-wrapper {
    display: flex;
    align-items: center;
}

.overlay {
    z-index: 100;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.connection-pane {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .ag-tool-panel,
    .ag-header {
        display: none;
    }
    .ag-side-bar,
    .ag-root {
        border: none !important;
    }
    .ag-root {
        font: 400 14px Segoe UI WestEuropean, Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue,
            sans-serif;
    }
    .ag-row {
        border: 1px transparent !important;
    }
    .ag-row-group-indent-1 {
        padding-left: 10px;
    }
    .ag-row-group-indent-2 {
        padding-left: 16px;
    }
    .ag-row-group-indent-3 {
        padding-left: 28px;
    }
    .ag-row-group-indent-4 {
        padding-left: 28px;
    }
    .ag-center-cols-viewport {
        overflow-x: hidden;
    }
}

.ag-body {
    .ms-Icon {
        font-size: 16px;
        vertical-align: bottom;
    }
}

.ag-row-selected .scope {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-themeSecondary;
}

.ag-group-value {
    width: 100%;
}

.row-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 6px;
    overflow: hidden;
}

.row-content-left {
    overflow: hidden;
    text-overflow: ellipsis;
}

.row-content-data-type {
    text-align: right;
    color: $color-neutralSecondary;
    font-size: 12px;
}

.row-content-action-icons .ms-Icon {
    margin-left: 8px;
    cursor: pointer;
}

.ag-row-focus .row-content-action-icons,
.ag-row-hover .row-content-action-icons {
    display: flex;
}

.row-content-status-icon {
    display: flex;
}

.ag-row-focus .row-content-status-icon,
.ag-row-hover .row-content-status-icon {
    display: none;
}

.row-content-action-icons {
    display: none;
}

// ag-grid has alternating rows, we don't want theme in connection pane (but we do want them in other grids)
.connection-pane.ag-theme-balham-dark {
    background-color: #1b1a19;
    .ag-row-even {
        background-color: #1b1a19;
        color: #c8c8c8;
    }
    .ag-row-odd {
        background-color: #1b1a19;
        color: #c8c8c8;
    }
    .ag-body {
        background-color: #1b1a19;
        color: #c8c8c8;
    }
    .row-content-right i:focus {
        outline-color: #c8c8c8;
    }
}

// ag-grid has alternating rows, we don't want theme in connection pane (but we do want them in other grids)
.connection-pane.ag-theme-balham {
    .ag-row-even {
        background-color: white;
        color: black;
    }
    .ag-row-odd {
        background-color: white;
        color: black;
    }
    .ag-body {
        background-color: white;
        color: black;
    }
    .row-content-right i:focus {
        outline-color: black;
    }
}

.row-content-right i:focus {
    outline-style: solid;
    outline-width: 1px;
    outline-offset: -0.5px;
}

body.using-mouse .row-content-right i {
    outline: none;
}

.screen-reader-only {
    position: absolute;
    height: 1px;
    width: 1px;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
    -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
    overflow: hidden !important;
}
