.splitPaneAnimatedClose {
    animation: fly-out 500ms;
    animation-fill-mode: forwards;
}

@keyframes fly-out {
    100% {
        width: 0;
    }
}
