@use '@kusto/style/tokens';

.horizontalSlider {
    cursor: ns-resize;
    height: 9px;
    z-index: 1;
    position: relative;
    margin-bottom: -5px;
    margin-top: -4px;
    margin-left: 1px;
    margin-right: 1px;

    &:focus-visible {
        outline: none;

        &::after {
            content: '';
            position: absolute;
            inset: 0 calc(-1 * tokens.$strokeWidthThick);
            border-radius: tokens.$borderRadiusMedium;
            border: tokens.$strokeWidthThick solid tokens.$colorStrokeFocus2;
        }
    }
}

.verticalSlider {
    width: 9px;
    margin-right: -5px;
    margin-left: -4px;

    z-index: 1;
    cursor: ew-resize;
    position: relative;

    &:focus-visible {
        outline: none;

        &::after {
            content: '';
            position: absolute;
            inset: 0 calc(-1 * tokens.$strokeWidthThick);
            border-radius: tokens.$borderRadiusMedium;
            border: tokens.$strokeWidthThick solid tokens.$colorStrokeFocus2;
        }
    }
}
