@use '@kusto/style/tokens';

$kusto-background-color: #1b1a19;
$kusto-alt-background-color: #201f1e;
$kusto-header-background-color: #252423;
$kusto-foreground-color: #f3f2f1;
$kusto-inverted-text-color: #000000;
$color-verboseForegroundDark: lightsteelblue;
$color-warningForegroundDark: gold;
$color-errorForegroundDark: #f8696b;
$color-criticalForegroundDark: black;
$color-criticalBackgroundDark: white;

.ag-theme-balham-dark.grid-with-kusto-data {
    background-color: $kusto-background-color;
    color: $kusto-foreground-color;

    .ag-row-even {
        background-color: $kusto-background-color;
    }

    .ag-row-odd {
        background-color: $kusto-alt-background-color;
    }

    .ag-header-viewport {
        background-color: $kusto-header-background-color;
        box-shadow: inset 0px -1px 0px #c4c4c4, inset 0px 1px 0px #c4c4c4;
    }

    .ag-side-bar {
        .ag-side-buttons {
            background-color: $kusto-background-color;
        }
    }

    .highlighted-url {
        color: tokens.$colorBrandForegroundLink;
        &:active {
            color: tokens.$colorBrandForegroundLinkPressed;
        }
        &:hover {
            color: tokens.$colorBrandForegroundLinkHover;
        }
    }
}

// Adding !important to the background color, to avoid ag-grid overriding it with specific rules, like range selection, etc..
.ag-theme-balham-dark .ag-row,
.ag-theme-balham-dark .ag-row .ag-cell {
    &.highlight-color-0 {
        background-color: #005a9e !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-1 {
        background-color: #0c5f32 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-2 {
        background-color: #5b1382 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-3 {
        background-color: #952f15 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-4 {
        background-color: #8f1336 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-5 {
        background-color: #464775 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-6 {
        background-color: #13458f !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-7 {
        background-color: #025c5f !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-8 {
        background-color: #a52c00 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-9 {
        background-color: #0c5f32 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-10 {
        background-color: #72396a !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-11 {
        background-color: #0742ab !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-12 {
        background-color: #a98c0c !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-13 {
        background-color: #501b50 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-14 {
        background-color: #0047b3 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-15 {
        background-color: #073845 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-16 {
        background-color: #0078d4 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-17 {
        background-color: #107c41 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-18 {
        background-color: #7719aa !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-19 {
        background-color: #c43e1c !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-20 {
        background-color: #bc1948 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-21 {
        background-color: #888cc7 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-22 {
        background-color: #185abd !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-23 {
        background-color: #03787c !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-24 {
        background-color: #d83b01 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-25 {
        background-color: #107c41 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-26 {
        background-color: #974b8b !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-27 {
        background-color: #2266e3 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-28 {
        background-color: #7a6816 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-29 {
        background-color: #742774 !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-30 {
        background-color: #0066ff !important;
        color: $kusto-foreground-color;
    }
    &.highlight-color-31 {
        background-color: #0b556a !important;
        color: $kusto-foreground-color;
    }
}

.ag-theme-balham-dark .ag-row,
.ag-theme-balham-dark .ag-row .ag-cell {
    // blue theme
    &.blue-theme-0 {
        background-color: #1b61a5 !important;
    }
    &.blue-theme-1 {
        background-color: #1467b1 !important;
    }
    &.blue-theme-2 {
        background-color: #0e6cbc !important;
    }
    &.blue-theme-3 {
        background-color: #0772c8 !important;
        color: #ffffff;
    }
    &.blue-theme-4 {
        background-color: #0078d4 !important;
        color: $kusto-inverted-text-color;
    }
    &.blue-theme-5 {
        background-color: #0d7ddd !important;
        color: $kusto-inverted-text-color;
    }
    &.blue-theme-6 {
        background-color: #1a82e5 !important;
        color: $kusto-inverted-text-color;
    }
    &.blue-theme-7 {
        background-color: #2688ee !important;
        color: $kusto-inverted-text-color;
    }
    &.blue-theme-8 {
        background-color: #338df6 !important;
        color: $kusto-inverted-text-color;
    }
    &.blue-theme-9 {
        background-color: #4092ff !important;
        color: $kusto-inverted-text-color;
    }

    // cold theme
    &.cold-theme-0 {
        background-color: #216895 !important;
    }
    &.cold-theme-1 {
        background-color: #217491 !important;
    }
    &.cold-theme-2 {
        background-color: #20818d !important;
        color: $kusto-inverted-text-color;
    }
    &.cold-theme-3 {
        background-color: #208d89 !important;
        color: $kusto-inverted-text-color;
    }
    &.cold-theme-4 {
        background-color: #1f9a85 !important;
        color: $kusto-inverted-text-color;
    }
    &.cold-theme-5 {
        background-color: #19a790 !important;
        color: $kusto-inverted-text-color;
    }
    &.cold-theme-6 {
        background-color: #13b49b !important;
        color: $kusto-inverted-text-color;
    }
    &.cold-theme-7 {
        background-color: #0cc1a6 !important;
        color: $kusto-inverted-text-color;
    }
    &.cold-theme-8 {
        background-color: #06ceb1 !important;
        color: $kusto-inverted-text-color;
    }
    &.cold-theme-9 {
        background-color: #00dbbc !important;
        color: $kusto-inverted-text-color;
    }

    // red theme
    &.red-theme-0 {
        background-color: #a31b15 !important;
    }
    &.red-theme-1 {
        background-color: #ab1818 !important;
    }
    &.red-theme-2 {
        background-color: #b4151a !important;
    }
    &.red-theme-3 {
        background-color: #bc121d !important;
    }
    &.red-theme-4 {
        background-color: #c50f1f !important;
    }
    &.red-theme-5 {
        background-color: #cc2431 !important;
    }
    &.red-theme-6 {
        background-color: #d33943 !important;
        color: #ffffff;
    }
    &.red-theme-7 {
        background-color: #da4f55 !important;
        color: $kusto-inverted-text-color;
    }
    &.red-theme-8 {
        background-color: #e16467 !important;
        color: $kusto-inverted-text-color;
    }
    &.red-theme-9 {
        background-color: #e87979 !important;
        color: $kusto-inverted-text-color;
    }

    // traffic-lights theme
    &.traffic-lights-theme-0 {
        background-color: #a8361d !important;
    }
    &.traffic-lights-theme-1 {
        background-color: #b64f27 !important;
    }
    &.traffic-lights-theme-2 {
        background-color: #c46732 !important;
        color: $kusto-inverted-text-color;
    }
    &.traffic-lights-theme-3 {
        background-color: #d2803c !important;
        color: $kusto-inverted-text-color;
    }
    &.traffic-lights-theme-4 {
        background-color: #e09846 !important;
        color: $kusto-inverted-text-color;
    }
    &.traffic-lights-theme-5 {
        background-color: #be9240 !important;
        color: $kusto-inverted-text-color;
    }
    &.traffic-lights-theme-6 {
        background-color: #9d8c39 !important;
        color: $kusto-inverted-text-color;
    }
    &.traffic-lights-theme-7 {
        background-color: #7b8533 !important;
        color: $kusto-inverted-text-color;
    }
    &.traffic-lights-theme-8 {
        background-color: #5a7f2c !important;
        color: $kusto-inverted-text-color;
    }
    &.traffic-lights-theme-9 {
        background-color: #387926 !important;
    }

    // warm theme
    &.warm-theme-0 {
        background-color: #a82e0f !important;
    }
    &.warm-theme-1 {
        background-color: #b53d0b !important;
    }
    &.warm-theme-2 {
        background-color: #c34d08 !important;
        color: #ffffff;
    }
    &.warm-theme-3 {
        background-color: #d05c04 !important;
        color: $kusto-inverted-text-color;
    }
    &.warm-theme-4 {
        background-color: #de6c00 !important;
        color: $kusto-inverted-text-color;
    }
    &.warm-theme-5 {
        background-color: #de750e !important;
        color: $kusto-inverted-text-color;
    }
    &.warm-theme-6 {
        background-color: #df7e1c !important;
        color: $kusto-inverted-text-color;
    }
    &.warm-theme-7 {
        background-color: #df862a !important;
        color: $kusto-inverted-text-color;
    }
    &.warm-theme-8 {
        background-color: #e08f38 !important;
        color: $kusto-inverted-text-color;
    }
    &.warm-theme-9 {
        background-color: #e09846 !important;
        color: $kusto-inverted-text-color;
    }

    // yellow theme
    &.yellow-theme-0 {
        background-color: #de750e !important;
        color: $kusto-inverted-text-color;
    }
    &.yellow-theme-1 {
        background-color: #df7e1c !important;
        color: $kusto-inverted-text-color;
    }
    &.yellow-theme-2 {
        background-color: #df862a !important;
        color: $kusto-inverted-text-color;
    }
    &.yellow-theme-3 {
        background-color: #e08f38 !important;
        color: $kusto-inverted-text-color;
    }
    &.yellow-theme-4 {
        background-color: #e09846 !important;
        color: $kusto-inverted-text-color;
    }
    &.yellow-theme-5 {
        background-color: #e6a738 !important;
        color: $kusto-inverted-text-color;
    }
    &.yellow-theme-6 {
        background-color: #ecb62a !important;
        color: $kusto-inverted-text-color;
    }
    &.yellow-theme-7 {
        background-color: #f1c51c !important;
        color: $kusto-inverted-text-color;
    }
    &.yellow-theme-8 {
        background-color: #f7d40e !important;
        color: $kusto-inverted-text-color;
    }
    &.yellow-theme-9 {
        background-color: #fde300 !important;
        color: $kusto-inverted-text-color;
    }
}

#table-result-container {
    &.ag-theme-balham-dark .ag-row:not(.ag-row-hover) {
        &.error-color {
            color: $color-errorForegroundDark;
        }
        &.warning-color {
            color: $color-warningForegroundDark;
        }
        &.verbose-color {
            color: $color-verboseForegroundDark;
        }
        &.critical-color {
            background: $color-criticalBackgroundDark;
            color: $color-criticalForegroundDark;
        }
    }
}
