.stylesWrapper {
    container-type: inline-size;
    container-name: schema-column-list;
}

// Large
@container schema-column-list (300px < width) {
    .schemaColumnList {
        --list-padding: var(--spacingVerticalL) var(--spacingHorizontalXL) 0;
    }
}

// Medium
@container schema-column-list (180px < width) and (width <= 300px) {
    .schemaColumnList {
        --list-padding: var(--spacingVerticalL) var(--spacingHorizontalM) 0;
    }
}

// Small
@container schema-column-list (width <= 180px) {
    .schemaColumnList {
        --list-padding: var(--spacingVerticalS) var(--spacingHorizontalSNudge) 0;
    }
}

.stylesWrapper {
    width: 100%;
    height: 100%;

    .schemaColumnList {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        height: 100%;
        padding: var(--list-padding);
    }
}
