.stylesWrapper {
    container-type: inline-size;
    container-name: schema-column-list;
}

// Large
@container schema-column-list (300px < width) {
    .schemaColumnList {
        --list-padding: var(--spacingVerticalL) var(--spacingHorizontalXL) 0;
    }
}

// Medium
@container schema-column-list (180px < width) and (width <= 300px) {
    .schemaColumnList {
        --list-padding: var(--spacingVerticalL) var(--spacingHorizontalM) 0;
    }
}

// Small
@container schema-column-list (width <= 180px) {
    .schemaColumnList {
        --list-padding: var(--spacingVerticalS) var(--spacingHorizontalSNudge) 0;
    }
}

.stylesWrapper {
    width: 100%;
    height: 100%;

    .schemaColumnList {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        height: 100%;
        padding: var(--list-padding);
    }
}

.listItemContainer {
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    height: 45px;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;

    .listItemButton {
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        color: var(--colorNeutralForeground1);

        .columnInfoContainer {
            margin-left: auto;
            flex: 0 0 auto;
        }

        .suffixIcon {
            margin-left: var(--spacingHorizontalXS);
            display: flex;
            justify-content: center;
        }

        .columnName {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 0 1 auto;
        }
    }
}

*[aria-expanded='true'] > .listItemContainer .columnName {
    font-weight: bold;
}
