.dataExploration {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacingVerticalM);

    .dataExplorationCard {
        height: 100%;
        display: flex;
        background: var(--colorNeutralBackground1);
        border-radius: var(--borderRadiusXLarge);
        box-shadow: var(--shadow4);
        overflow: hidden;

        .mainSection {
            height: 100%;
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .pillsSection {
                height: 64px;
                min-height: 64px; // prevent shrinking
                border-bottom: 1px solid var(--colorNeutralStroke1);
            }
            .splitPanePanel {
                height: 100%;
                overflow: hidden;
            }
        }
        .rightPane {
            position: relative;
            background: var(--colorNeutralBackground3);
            width: 100%;
            height: 100%;
            border-left: 1px solid var(--colorNeutralStroke1);
        }
        .rightPaneMinimized {
            width: 40px;
        }
    }
}
