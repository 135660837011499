/**
 * It's unfortunate but azure-maps-control hardcodes their colors for controls and
 * doesn't export them either via JS or CSS variables. This means we're matching
 * the hardcoded value but this could get out of sync if their next major version changes
 * the colors and we're not paying attention
 */
$darkModeControlBgColor: #011c2c;

:global(.azure-maps-control-container.dark) {
    .control {
        background-color: $darkModeControlBgColor;
    }
}
