$active: #0091ea;

:not(:global(.ag-cell-focus)).expandClose {
    display: none;
}
.expandClose:hover {
    color: red;
}

.expandInGrid :global(.ag-row-group-expanded) :global(.ag-cell-focus) .expandClose {
    display: inline-block;
    position: absolute;
    font-size: 20px;
    top: 0;
    right: 0;
    z-index: 1;

    &:hover {
        color: red;
    }
}

:global(.ag-row-group-expanded) :global(.ag-cell-focus):has(.expandClose) {
    padding-right: 20px;
}

.splitPane {
    & > div[data-type='Resizer'] {
        cursor: auto;
    }
}

.splitPane.resizeEnabled {
    & > div[data-type='Resizer'] {
        cursor: ns-resize;
    }
}

:global(.ag-row .ag-cell.ms-Icon.rowExpandIndicator) {
    padding: 0px 5px;
    text-overflow: clip;
}

:global(.ag-row.cell-expand.ag-row-group-expanded) {
    :global(.ag-cell) {
        border-bottom: 1px solid $active;
        border-right-width: 0px;
        border-left-width: 0px;
        padding-right: 12px;
        padding-left: 12px;
    }
    :global(.ag-cell-focus) {
        :global(.rowExpandIndicator) {
            padding: 1px 5px 0px 5px;
        }
        border-right: 1px solid $active;
        border-left: 1px solid $active;
        border-bottom: 0px solid transparent;
        padding-bottom: 1px;
        padding-right: 24px;
        height: calc(100% + 1px);
    }
    :global(.ag-cell-focus:focus) {
        border-top-width: 2px;
        margin-top: -1px;
    }
    overflow: visible;
}

:global(.ag-row.cell-expand.ag-row-group-expanded::before) {
    content: '';
    border-bottom: 1px solid $active;
    position: absolute;
    bottom: 0px;
    width: 5000px;
}
:global(.ag-row.detailed-row.cell-expand-inline) {
    border-style: solid;
    border-color: $active;
    border-width: 0px 1px 1px 1px;
    padding: 0px 1px 0px 1px;
}
