@use '@kusto/style/tokens';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    overflow: auto;
    width: 100%;

    :global(.fui-MenuItem) {
        background-color: unset;
        color: unset;
        padding: 0;
        min-height: unset;
        max-width: unset;
        &[data-fui-focus-visible] {
            background-color: tokens.$colorNeutralBackground1Hover;
            &::after {
                border: none;
            }
        }
    }

    :global(.fui-MenuItem__content) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    // Ensure legend item text ellipsis is shown when necessary
    [role='menu'] {
        overflow-x: hidden;
        max-width: 100%;
    }

    // scroll-bar - webkit Chrome/Edge
    ::-webkit-scrollbar {
        height: 4px;
        width: 4px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: tokens.$colorNeutralForeground4 !important;
        border-radius: 2px;
    }

    // This will apply to Firefox only
    @-moz-document url-prefix() {
        scrollbar-color: tokens.$colorNeutralForeground4 transparent;
        scrollbar-width: thin;
    }
}

.legendItem {
    border-radius: tokens.$borderRadiusMedium;
    cursor: pointer;
    direction: ltr; // needed for cases where the scroll-bar is on the left side
    height: 20px;
    width: 100%;
    scroll-snap-align: start; // Snap items to the start of the scroll container
    &:hover {
        background-color: tokens.$colorNeutralBackground1Hover;
    }
}

.seriesSymbol {
    font-size: tokens.$fontSizeBase200;
    width: 12px;
    height: 12px;
    display: inline-block;
    text-align: center;
}

.seriesName {
    margin-left: 4px;
    font-size: tokens.$fontSizeBase200;
}

.itemDisabled {
    span {
        color: tokens.$colorNeutralForegroundDisabled;
        fill: tokens.$colorNeutralForegroundDisabled;
    }
}

.selectedSeries {
    background-color: tokens.$colorNeutralBackground1Hover !important;
}

.list {
    overflow: auto;
    padding: 2px 4px;
}

.horizontalList {
    justify-content: space-evenly;
}

.actionsBar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    direction: ltr; // Ensure LTR direction, even when in RTL mode (when in legend left position)
}

.actions {
    height: 24px;
    direction: ltr; // Ensure LTR direction, even when in RTL mode (when in legend left position)
    width: 100%;
}

.search {
    max-height: 24px;
    margin: 4px 0;
    max-width: 250px;
    direction: ltr; // Ensure LTR direction, even when in RTL mode (when in legend left position)
}

.arrowUp {
    transform: rotate(180deg);
}

.horizontalContainer {
    .list {
        display: grid;
        grid-template-rows: repeat(3, 1fr); // 3 items per column
        grid-auto-flow: column;
        gap: 0 8px;
        overflow-y: hidden;
        overflow-x: auto;
        scroll-snap-type: x mandatory; // Enable horizontal scroll snapping
    }

    ::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 2px;
    }

    &:hover::-webkit-scrollbar-thumb {
        background: tokens.$colorNeutralForeground4;
    }
}

.scrollButtons {
    height: 32px;
}
