.filterPopover {
    // prevent panel from resizing horizontally when fields are added
    min-width: 620px;
}

.aggregationPopover {
    // prevent panel from resizing horizontally when fields are added
    min-width: 620px;

    .aggregationGroups {
        display: flex;
        flex-direction: column;
        gap: var(--spacingHorizontalM);
    }
}

.pillPopover {
    position: relative;

    .popoverHeader {
        display: flex;
        flex-direction: column;

        .popoverSubtitle {
            color: var(--colorNeutralForeground3);
            margin-top: var(--spacingVerticalXS);
        }
    }

    .popoverTabs {
        position: absolute;
        top: 0;
        right: 0;
    }

    .popoverForm {
        display: flex;
        flex-direction: column;
        gap: var(--spacingVerticalS);
        padding-top: var(--spacingVerticalL);

        .divider {
            margin: var(--spacingVerticalS) 0;
        }

        .formRow {
            display: flex;
            gap: var(--spacingHorizontalL);
            align-items: end;

            .popoverField {
                width: 180px;
                min-width: unset !important; // override Fluent's style
                height: 32px;

                :first-child {
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
            .popoverFieldSmall {
                width: 82px;
            }

            .removeButton {
                position: absolute;
                right: 0;
            }
        }
        .addButton {
            align-self: flex-start;
            margin-top: var(--spacingVerticalS);
        }
    }
}

.columnDropDownOption {
    display: flex;
    align-items: center;
    gap: 12px;
}

.columnDropDownOptionText {
    overflow: hidden;
    overflow-wrap: break-word;
}

.dropdownButton {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--colorNeutralForeground1);
}

.popoverButtons {
    position: relative;
    display: flex;
    gap: var(--spacingHorizontalS);
    justify-content: flex-end;
    margin-top: var(--spacingVerticalXXL);
}

.listbox {
    max-height: 280px !important;
}

.truncatedText {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
