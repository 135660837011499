.root {
    display: flex;
    flex-direction: column;
    margin-left: var(--spacingHorizontalS);
    margin-right: var(--spacingHorizontalS);
}

.headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.farButtonsWrapper {
    display: flex;
    gap: var(--spacingHorizontalS);
}

.contentWrapper {
    display: flex;
    align-items: center;
    padding: 0 var(--spacingHorizontalS);
    width: 100%;
    height: 40px;
    background-color: var(--colorNeutralBackground1);
    border-radius: var(--borderRadiusXLarge);
    box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px;
    box-sizing: border-box;
    transition: height 150ms cubic-bezier(0.1, 0.9, 0.2, 1);
    border-bottom: none;
    & button {
        border-style: none;
        font-weight: var(--fontWeightRegular);
    }
}

.tab {
    padding: 5px;
}
