.scrollbar-container {
    /*
    * Scrollbar rail styles
    */
    .ps__rail-x {
        height: 5px;
        /* there must be 'bottom' or 'top' for ps__rail-x */
        bottom: 0px;
        /* please don't change 'position' */
        z-index: 1;
        max-width: 100%;
    }
    /*
    * Scrollbar thumb styles
    */
    .ps__thumb-x {
        opacity: 0.8;
        border-radius: 6px;
        bottom: 0px;
        height: 5px;
    }

    .ps .ps__rail-x:hover,
    .ps .ps__rail-y:hover,
    .ps .ps__rail-x:focus,
    .ps .ps__rail-y:focus,
    .ps .ps__rail-x.ps--clicking,
    .ps .ps__rail-y.ps--clicking {
        opacity: 1;
        background-color: unset;
    }

    .ps__rail-x:hover > .ps__thumb-x,
    .ps__rail-x:focus > .ps__thumb-x,
    .ps__rail-x.ps--clicking .ps__thumb-x {
        opacity: 1;
        height: 5px;
    }
    .ps--active-x > .ps__rail-x {
        background-color: unset;
    }
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
    opacity: 1 !important;
}

.scrollbar-container.dark {
    &.ps--active-x > .ps__rail-x {
        background-color: rgba(50, 49, 48, 0.8);
        border-top: 1px solid #292827;
        border-bottom: 1px solid #323130;
    }

    .ps__thumb-x {
        background-color: #484644;
    }

    .ps__thumb-y {
        background-color: #484644;
    }
}

.scrollbar-container.light {
    &.ps--active-x > .ps__rail-x {
        background-color: rgba(250, 250, 250, 0.8);
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #c8c6c4;
    }

    .ps__thumb-x {
        background-color: #c1c1c1;
    }

    .ps__thumb-y {
        background-color: #c1c1c1;
    }
}
