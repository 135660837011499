.checkboxContainer {
    display: flex;

    > .infoIconTooltipHost {
        align-self: stretch; // Increase area cursor can hover to see icon
    }
}

.infoIconTooltipHost {
    display: grid;
    place-content: center;

    // Copy of Fluent 8 outline styles
    i:focus-visible {
        box-shadow: var(--neutralSecondary) 1px inset;
        outline: var(--neutralSecondary) auto 1px;
    }
}

.dropdownIconContainer {
    @extend .checkboxContainer;

    // Fluent 8 applies a ton of right padding to this. Not sure why? It makes
    // the info icon appear too far away from everything else.
    label::after {
        padding: unset;
    }
}
