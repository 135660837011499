@use '@kusto/style/tokens';

.formContainer {
    display: flex;
    flex-direction: column;
    gap: tokens.$spacingHorizontalL;

    :global(.fui-Switch) {
        width: fit-content;
    }
}
