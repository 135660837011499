$ag-icons-path: '../connectionExplorer/balham-icons/dark/';

@media screen and (-ms-high-contrast: active) {
    .connection-pane.ag-theme-balham-dark .ag-row-selected {
        background-color: highlight !important;
        border: 1px solid highlight !important;
    }
}

.ag-theme-balham-dark .datatype {
    text-align: right;
    color: #d0d0d0;
    font-size: 12px;
}
.ag-theme-balham-dark .ag-tool-panel .ag-side-buttons button:focus {
    outline: #fff solid 1px;
}
.ag-theme-balham-dark div.ag-root-wrapper {
    background: unset;
}

.connection-pane.ag-theme-balham-dark .ag-root-wrapper {
    border-width: 1px 0 0 0;
}

.connection-pane.ag-theme-balham-dark .ag-row.ag-row-selected {
    background-color: #005880;
}

.ag-theme-balham-dark .ag-cell-search-hit {
    background-color: rgba(255, 170, 0, 0.2);
    color: #fff;
}

.ag-theme-balham-dark .ag-cell-search-hit-current {
    background-color: rgba(255, 106, 0, 0.3);
    color: #fff;
}

.ag-theme-balham-dark .ag-cell-search-hit-focus {
    border: 1px solid #00b0ff !important;
}
