.mapTooltipWrapper {
    padding: 12px 16px;
    color: #323130;
    display: grid;
    grid-template-columns: [first] 12px [second] auto;
    column-gap: 4px;
    background: #ffffff;
}
.darkTheme {
    background: #1b1a19;
}
.coordinates {
    font-size: 12px;
    grid-row: 1;
    grid-column: 1 / span 2;
    margin-bottom: 8px;
}
.circle {
    grid-row: 2;
    grid-column: 1;
}
.argument {
    grid-row: 2;
    grid-column: 2;
}
.value {
    grid-row: 3;
    grid-column: 2;
    font-weight: bold;
}
.successRoot {
    height: 100%;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
