@use '@kusto/style/tokens';
@use '@kusto/style/typography';

.messageTitle {
    @extend %typography--caption1Strong;
    text-wrap: balance;
    margin: 0;
}

.messageBody {
    @extend %typography--caption1;
    text-wrap: pretty;
}

.messageTitle,
.messageBody {
    white-space: pre-wrap;
    hyphens: auto;
    overflow-wrap: break-word;
}
