.listItemContainer {
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    border-bottom: var(--strokeWidthThin) var(--colorNeutralStroke2) solid;
    height: 45px;
    align-items: center;

    .listItemButton {
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        color: var(--colorNeutralForeground1);

        .columnInfoContainer {
            margin-left: auto;
            flex: 0 0 auto;
        }

        .suffixIcon {
            margin-left: var(--spacingHorizontalXS);
            display: flex;
            justify-content: center;
        }

        .columnName {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 0 1 auto;
        }
    }

    &.selected {
        background-color: var(--colorNeutralBackground1Selected);
    }
}

.listSectionHeader {
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    border-bottom: var(--strokeWidthThin) var(--colorNeutralStroke1Selected) solid;
    height: 45px;
    align-items: center;
    padding-left: var(--spacingHorizontalM);
}

.listContainer {
    width: 100%;
}

.pretty .listItemContainer.selected {
    border-bottom: var(--strokeWidthThick) var(--colorCompoundBrandStroke) solid;
    border-radius: var(--borderRadiusMedium);
}
