.results {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .tabsBar {
        display: flex;
        padding: 0 var(--spacingVerticalM);

        .tabsList {
            flex: 1;
        }

        .resultsCount {
            align-self: center;
            margin-right: 32px; // shift label from expand button
        }
    }

    .resultsContact {
        flex: 1;
        padding: var(--spacingVerticalS) var(--spacingHorizontalXXL) var(--spacingVerticalL);
        overflow: hidden;
    }
}

.loader {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
