/*
 Sass docs: https://sass-lang.com/documentation/style-rules/placeholder-selectors
 Fluent UI docs: https://react.fluentui.dev/?path=/docs/theme-typography--page
 
 ## Example Usage
 
 ```scss
 @use '@kusto/style/typography';
 
 .myButton {
     @extend typography.%typography--body1;
 }
 
 Or like this: 

 @use '@kusto/style/typography' as *;
 
 .myButton {
     @extend %typography--body1;
 }
 ```
 */

%typography--body1 {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeBase300);
    font-weight: var(--fontWeightRegular);
    line-height: var(--lineHeightBase300);
}

%typography--body1Strong {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeBase300);
    font-weight: var(--fontWeightSemibold);
    line-height: var(--lineHeightBase300);
}

%typography--body1Stronger {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeBase300);
    font-weight: var(--fontWeightBold);
    line-height: var(--lineHeightBase300);
}

%typography--body2 {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeBase400);
    font-weight: var(--fontWeightRegular);
    line-height: var(--lineHeightBase400);
}

%typography--caption1 {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeBase200);
    font-weight: var(--fontWeightRegular);
    line-height: var(--lineHeightBase200);
}

%typography--caption1Strong {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeBase200);
    font-weight: var(--fontWeightSemibold);
    line-height: var(--lineHeightBase200);
}

%typography--caption1Stronger {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeBase200);
    font-weight: var(--fontWeightBold);
    line-height: var(--lineHeightBase200);
}

%typography--caption2 {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeBase100);
    font-weight: var(--fontWeightRegular);
    line-height: var(--lineHeightBase100);
}

%typography--caption2Strong {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeBase100);
    font-weight: var(--fontWeightSemibold);
    line-height: var(--lineHeightBase100);
}

%typography--subtitle1 {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeBase500);
    font-weight: var(--fontWeightSemibold);
    line-height: var(--lineHeightBase500);
}

%typography--subtitle2 {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeBase400);
    font-weight: var(--fontWeightSemibold);
    line-height: var(--lineHeightBase400);
}

%typography--subtitle2Stronger {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeBase400);
    font-weight: var(--fontWeightBold);
    line-height: var(--lineHeightBase400);
}

%typography--title1 {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeHero800);
    font-weight: var(--fontWeightSemibold);
    line-height: var(--lineHeightHero800);
}

%typography--title2 {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeHero700);
    font-weight: var(--fontWeightSemibold);
    line-height: var(--lineHeightHero700);
}

%typography--title3 {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeBase600);
    font-weight: var(--fontWeightSemibold);
    line-height: var(--lineHeightBase600);
}

%typography--largeTitle {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeHero900);
    font-weight: var(--fontWeightSemibold);
    line-height: var(--lineHeightHero900);
}

%typography--display {
    font-family: var(--fontFamilyBase);
    font-size: var(--fontSizeHero1000);
    font-weight: var(--fontWeightSemibold);
    line-height: var(--lineHeightHero1000);
}
