.pillsBar {
    box-sizing: border-box;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    gap: var(--spacingHorizontalS);
    padding: var(--spacingVerticalL) var(--spacingHorizontalXXL);

    .pillsGroup {
        width: calc(100% - 72px); // static width for overflow support

        .pillsList {
            display: flex;
            flex: 1;

            .pill {
                min-width: 80px;
                max-width: 200px;
                // Overflow items can't have spacing with `gap` or with `margin`.
                // The overflow manager won't know how to calculate the width.
                // Therefore, we padding to create the spacing.
                padding: 0 var(--spacingHorizontalXS);

                .pillButton {
                    width: 100%;
                    display: flex;
                    overflow: hidden;

                    // Pill text (can't use class selector, no access to this element - generated by Fluent)
                    :nth-child(2) {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        // any child of the pill text (like bold text)
                        * {
                            white-space: nowrap;
                        }
                    }
                }
            }

            .divider {
                flex: 0;
            }

            .addButton {
                min-width: unset;
            }
        }
    }

    .farButtons {
        margin-left: auto;
    }
}

.pillTooltipSurface {
    max-width: unset !important; // override Fluent's style
}
