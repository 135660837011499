@use '@kusto/style/tokens';

button.button {
    font-weight: normal;
    padding: 8px 6px;
    svg {
        color: tokens.$colorPaletteBlueBorderActive;
    }

    p {
        margin: 0 0 0 4px;
        font-weight: normal;
        white-space: nowrap;
    }
}

.containerWhenNoAction {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-right: 10px;
    white-space: nowrap;
}
