$customicons: Cluster, Column, FunctionsFolder, TablesFolder, AddCluster, ImportClusters, FavoriteListOn, Recall,
    AddClusterDisabled, ImportClustersDisabled, RecallDisabled, ExportCsv, ExternalTable, FollowerDatabase,
    ReadOnlyDatabase, MaterializedViewTableFolder, ExternalTablesFolder, ViewFunction, EntityGroupFolder, EntityGroup,
    EntityGroupMember;
.ms-Icon[class*='ms-Icon--CI-'] {
    &::before {
        content: '';
        background-repeat: no-repeat;
        width: 1em;
        height: 1em;
        display: inline-block;
        background-size: contain;
        background-position: center center;
    }
}

.ag-ltr .ag-row-group-leaf-indent {
    margin-left: 28px !important;
}

.ms-Icon.ms-Icon--FavoriteList {
    // Special case to keep FavoriteList and FavoriteListOn identical sizes
    font-size: 16px;
}

@each $icon in $customicons {
    .ms-Icon--CI-#{$icon} {
        &::before {
            background-image: url('data-url:./icons/light/#{$icon}.svg');
        }
    }
}

@each $icon in $customicons {
    .ag-theme-balham-dark .ms-Icon--CI-#{$icon} {
        &::before {
            background-image: url('data-url:./icons/dark/#{$icon}.svg');
        }
    }
}
