@use '@kusto/style/tokens';

.rulesList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.colorRuleRow {
    padding: 4px 0 10px 0;
    border-bottom: 1px solid tokens.$colorNeutralStroke2;
}

.colorRuleRow:last-child {
    border-bottom: none;
}

.indicatorsContainer {
    height: 20px;
    margin-top: 16px;
    display: flex;
    align-items: center;
}

.ruleConfigText {
    margin-left: 4px;
    color: var(--neutralSecondary);
}
